<template>
  <div
    :class="[
      'cy-pagination d-flex align-center',
      { 'cy-pagination--header': isHeader },
    ]"
    v-bind="$attrs"
    v-on="$listeners">
    <div
      v-if="!isHeader"
      class="cy-pagination__selector">
      <span>{{ $t('showing') }}</span>
      <CyMenu
        data-cy="pagination-selector-btn"
        content-class="cy-menu-pagination"
        offset-y>
        <template #activator="{ on, attrs }">
          <CyButton
            class="rounded pl-3 pr-1 mx-2"
            theme="primary"
            variant="secondary"
            outlined
            v-bind="attrs"
            v-on="on">
            {{ options.itemsPerPage }}
            <v-icon class="ml-2">
              arrow_drop_down
            </v-icon>
          </CyButton>
        </template>
        <v-list>
          <v-list-item-group
            :value="options.itemsPerPage"
            mandatory>
            <v-list-item
              v-for="(item, index) in itemsPerPageOptions"
              :key="index"
              :value="item"
              @click="updateItemsPerPage(item)">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </CyMenu>
      <span>{{ $t('outOf') }} {{ itemsLength }}</span>
    </div>

    <v-spacer v-if="!isHeader"/>

    <v-toolbar
      class="elevation-0"
      rounded
      dense
      :tag="isHeader ? 'header' : 'footer'">
      <CyButton
        class="rounded rounded-r-0 border-r-0 px-2"
        data-cy="pagination-previous-btn"
        icon="chevron_left"
        theme="primary"
        variant="secondary"
        :disabled="options.page === 1"
        @click="goToPage(options.page - 1)"/>
      <v-text-field
        v-model.number="pageUserInput"
        class="ma-0 rounded-0"
        data-cy="pagination-input"
        type="number"
        outlined
        hide-details
        hide-spin-buttons
        dense
        :disabled="numberOfPages === 1"
        :placeholder="`${options.page} / ${numberOfPages}`"
        @keyup.enter="goToPage(pageUserInput), $event.target.blur()"
        @blur="goToPage(pageUserInput)"/>
      <CyButton
        class="rounded rounded-l-0 px-2"
        data-cy="pagination-next-btn"
        icon="chevron_right"
        theme="primary"
        variant="secondary"
        :disabled="options.page === numberOfPages"
        @click="goToPage(options.page + 1)"/>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'CyDataTablePagination',
  props: {
    options: {
      type: Object,
      required: true,
    },
    itemsLength: {
      type: Number,
      required: true,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [25, 50, 100, 200],
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    pageUserInput: null,
  }),
  computed: {
    numberOfPages () {
      return Math.ceil(this.itemsLength / this.options.itemsPerPage)
    },
  },
  methods: {
    goToPage (page) {
      if (page === null) return
      this.pageUserInput = null
      this.$emit('update:options', {
        ...this.options,
        page: _.clamp(page, 1, this.numberOfPages),
      })
    },
    updateItemsPerPage (option) {
      if (option === this.options.itemsPerPage) return

      this.$emit('update:options', {
        ...this.options,
        page: 1,
        itemsPerPage: option,
      })
    },
  },
  i18n: {
    messages: {
      en: {
        showing: 'Showing',
        outOf: 'out of',
      },
      es: {
        showing: 'Mostrando',
        outOf: 'de',
      },
      fr: {
        showing: 'Affichage de',
        outOf: 'sur',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-pagination {
  &--header {
    position: absolute;
    right: 0;
    transform: translate3d(0, calc(-100% - #{$spacer-2}), 0);
  }

  ::v-deep .v-toolbar {
    display: flex;
    justify-content: flex-end;
    background-color: transparent;

    &__content {
      padding-right: 0;
    }

    .v-btn {
      .v-icon {
        margin: 0 !important;
        font-size: 24px !important;
      }
    }

    .v-text-field {
      flex-grow: 0;

      fieldset {
        margin: 0 -1px;
      }

      &.v-input--is-focused {
        fieldset {
          border-width: 1px;
        }
      }

      .v-input__control .v-input__slot {
        min-height: 36px;
        margin-bottom: 0;
        padding: 0;

        input {
          width: 70px;
          text-align: center;

          &:focus::placeholder {
            color: transparent;
          }
        }
      }
    }
  }
}
</style>
