<template>
  <v-data-table
    :items="filteredItems"
    :options.sync="options"
    :filters="$static.filters"
    :loading="loading"
    :headers="$static.headers"
    hide-default-footer
    class="accounts-table elevation-2">
    <template #top>
      <v-row
        align="center"
        class="table-header pa-4"
        no-gutters>
        <transition
          name="slide-fade-left"
          mode="out-in">
          <v-col
            v-if="searchActive"
            key="search"
            class="table-header__searchbar pa-0">
            <v-text-field
              ref="headerSearchbar"
              v-model.trim="searchTerm"
              autofocus
              :placeholder="searchPlaceholder"
              append-icon="search"
              clearable
              data-cy="ccm-search-field"
              class="search-field"
              @click:clear="$toggle.searchActive(true)"
              @blur="$toggle.searchActive(false)"/>
          </v-col>
        </transition>

        <transition name="slide-fade-right">
          <v-row
            v-if="!searchActive"
            key="filters"
            no-gutters
            class="table-header__filters">
            <v-icon
              color="primary"
              data-cy="search-button"
              class="mr-6"
              @click="$toggle.searchActive">
              search
            </v-icon>
            <CyDataTableFilters
              v-if="!_.$isEmpty($static.filters)"
              class="mx-2"
              :filters="$static.filters"
              height="100%">
              <template #item-content="{ item }">
                <template v-if="item.key === 'cloudProvider'">
                  <CyIconCredential
                    size="24"
                    class="mr-4"
                    :type="item.type"/>
                  {{ item.value }}
                </template>
              </template>
            </CyDataTableFilters>
            <CyButton
              v-has-rights-to="'CreateCloudCostManagementAccount'"
              class="add-account-btn align-self-center"
              data-cy="link-add-account"
              icon="add"
              :to="{ name: 'cloudCostManagementAccountsCredentials' }">
              {{ $t('routes.cloudCostManagementAccountsCredentials') }}
            </CyButton>
          </v-row>
        </transition>
      </v-row>
    </template>
    <template #item="{ item }">
      <tr
        class="row"
        data-cy="data-table-row"
        @click="goToAccount(item.canonical)">
        <td class="d-flex align-center">
          <CyIconCredential
            size="24"
            class="mr-4"
            :type="item.cloud_provider.canonical"/>
          {{ getProviderExtraInfoByCanonical[item.cloud_provider.canonical].displayNameFull }}
        </td>
        <td>{{ item.account_id }}</td>
        <td>{{ _.get(item.credential, 'name', '') }}</td>
        <td>{{ item.last_ingestion_ended_at ? formatDate(item.last_ingestion_ended_at) : '--' }}</td>
        <td class="text-right">
          <CyTag
            small
            :variant="getTagVariant(getAccountStatus(item.enabled, item.status))">
            <v-icon
              v-if="getAccountStatus(item.enabled, item.status) === 'errored'"
              small
              color="error dark"
              class="icon-error">
              warning
            </v-icon>
            {{ getStatusTranslation(item) }}
          </CyTag>
        </td>
      </tr>
    </template>
    <template #footer>
      <CyDataTablePagination
        v-if="filteredItems.length"
        class="v-data-footer px-4 py-1"
        :items-length="filteredItems.length"
        :options.sync="options"
        :items-per-page-options="itemsPerPageOptions"/>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import CyDataTableFilters from '@/components/data-table/filters.vue'
import CyDataTablePagination from '@/components/data-table/pagination.vue'
import { pagination as createAPIPage } from '@/utils/api'
import { getAccountStatus, getCredentialType } from '@/utils/helpers/cloud-cost-management'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export default {
  name: 'CyCloudCostManagementAccountsTable',
  components: {
    CyDataTableFilters,
    CyDataTablePagination,
  },
  data: () => ({
    loading: true,
    searchActive: false,
    searchTerm: '',
    itemsPerPageOptions: [10, 25, 50, 100],
  }),
  computed: {
    $static () {
      return {
        filters: [
          {
            type: 'select',
            label: this.$t('cloudProvider'),
            queryParams: ['cloudProvider'],
            items: this.cloudProviders,
          },
        ],
        headers: [
          {
            text: this.$t('cloudProvider'),
            align: 'left',
            width: '215px',
            value: 'cloud_provider.display_name',
          },
          {
            text: this.$t('accountId'),
            align: 'left',
            width: '180px',
            value: 'canonical',
          },
          {
            text: this.$t('cloudCostManagement.credentialName'),
            align: 'left',
            value: 'credential.name',
          },
          {
            text: this.$t('lastImportDate'),
            align: 'left',
            value: 'last_ingestion_ended_at',
            width: '100%',
          },
          {
            text: this.$t('forms.status'),
            align: 'right',
            value: 'status',
          },
        ],
        searchableFields: [
          {
            name: 'account_id',
            label: this.$t('accountId'),
          },
          {
            name: 'credential.name',
            label: this.$t('cloudCostManagement.credentialName'),
          },
          {
            name: 'cloud_provider.type',
            label: this.$t('cloudProvider'),
          },
          {
            name: 'status',
            label: this.$t('forms.status'),
          },
        ],
        tagVariants: {
          disabled: 'default',
          errored: 'error',
          enabled: 'secondary',
          importing: 'accent',
        },
      }
    },
    ...mapState('organization', {
      credentials: (state) => state.available.credentials,
    }),
    ...mapState('organization/cloudCostManagement', {
      accounts: (state) => state.accounts,
    }),
    ...mapGetters('layout', [
      'getDataTableFilters',
      'getDataTableProps',
    ]),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCanonical',
      'getProviderExtraInfoByCredType',
    ]),
    options: {
      get () {
        return this.getDataTableProps(this.$route.name)
      },
      set (props) {
        this.SET_DATA_TABLE_PROPS({ name: this.$route.name, props })
      },
    },
    cloudProviders () {
      return _.chain(this.accounts)
        .map(({ cloud_provider: cloudProvider }) => {
          const credentialType = this.getCredentialType(cloudProvider.canonical)
          const cloudProviderFullName = _.$get(this.getProviderExtraInfoByCredType, credentialType, {}).displayNameFull
          const cloudProviderShortName = _.$get(this.getProviderExtraInfoByCredType, credentialType, {}).displayName
          return {
            key: 'cloudProvider',
            type: credentialType,
            title: `${credentialType} ${cloudProviderFullName} ${cloudProviderShortName}`,
            value: cloudProviderFullName,
          }
        })
        .uniqBy('value')
        .value()
    },
    filteredItems () {
      return this.filterBySearch(this.filterByFilters(_.chain(this.accounts)
        .map((account) => {
          // credential canonical may be set in either `credential` or `external_backend.credential_canonical`,
          // depending if account is linked or master
          const credential = _.isEmpty(account.credential)
            ? _.find(this.credentials, ['canonical', account.external_backend?.credential_canonical])
            : account.credential
          const cloudProvider = {
            ...account.cloud_provider,
            display_name: this.getProviderExtraInfoByCanonical[account.cloud_provider.canonical]?.displayNameFull,
          }
          return {
            ...account,
            credential,
            cloud_provider: cloudProvider,
          }
        })
        .value()))
    },
    searchPlaceholder () {
      const searchBy = this.$t('forms.searchBy')
      const labels = _.map(this.$static.searchableFields, 'label')
      const conjunction = this.$t('or')
      const listOfLabels = _.$getListFromArray(labels, { conjunction })
      return `${searchBy} ${listOfLabels}`
    },
  },
  async mounted () {
    if (this.$cycloid.permissions.canDisplay('ListCredentials')) {
      await this.FETCH_AVAILABLE({ keyPath: 'credentials', extraParams: [null, createAPIPage(1, 100)] })
    }
    this.$toggle.loading(false)
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
    ]),
    getAccountStatus,
    getCredentialType,
    getStatusTranslation (item) {
      const status = getAccountStatus(item.enabled, item.status)
      return status === 'importing'
        ? `${this.$t('importing')}...`
        : this.$t(`cloudCostManagement.status.${status}`)
    },
    goToAccount (accountCanonical) {
      this.$router.push({
        name: 'cloudCostManagementAccountsAccount',
        params: { accountCanonical },
      })
    },
    filterByFilters (items) {
      const { 'cloudProvider[in]': cloudProvidersToFilter = '' } = this.getDataTableFilters(this.$route.name)

      return items.filter(({ cloud_provider: cloudProvider }) => {
        const credentialType = this.getCredentialType(cloudProvider.canonical)
        const providerFullName = _.$get(this.getProviderExtraInfoByCredType, credentialType, {}).displayNameFull
        return _.$isEmpty(cloudProvidersToFilter) || _.includes(cloudProvidersToFilter, providerFullName)
      })
    },
    filterBySearch (items) {
      return items.filter((item) => {
        const credentialType = this.getCredentialType(item.cloud_provider.canonical)
        for (const { name: property } of this.$static.searchableFields) {
          const fieldsToSearch = _.join([
            _.get(item, property, ''),
            `${this.getProviderExtraInfoByCredType[credentialType]?.displayName} ${this.getProviderExtraInfoByCredType[credentialType]?.displayNameFull}`,
            this.getAccountStatus(item.enabled, item.status),
          ], ' ')

          if (fieldsToSearch.toLowerCase().includes((this.searchTerm || '').toLowerCase())) return true
        }

        return false
      })
    },
    formatDate (date) {
      return !_.$isEmpty(date)
        ? moment(date * 1000).format('MMMM D, YYYY hh:mm a') // eslint-disable-line you-dont-need-momentjs/format
        : '--'
    },
    getTagVariant (text) {
      return _.get(this.$static.tagVariants, text, 'default')
    },
  },
  i18n: {
    messages: {
      en: {
        accountId: 'Account ID',
        cloudProvider: 'Cloud provider',
        lastImportDate: 'Last import date',
      },
      es: {
        accountId: 'ID de la cuenta',
        cloudProvider: 'Proveedor de nube',
        lastImportDate: 'Ultima fecha de importación',
      },
      fr: {
        accountId: 'Identifiant de compte',
        cloudProvider: 'Fournisseur de cloud',
        lastImportDate: `Dernière date d'importation`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.accounts-table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ::v-deep {
    .v-data-table__wrapper {
      border-bottom: solid 1px cy-get-color("grey", "light-1");

      .row {
        display: table-row;
        cursor: pointer;
      }
    }

    .icon-error {
      width: 12px;
      height: 11px;
      margin-right: 6px;
      margin-left: 2px;
    }
  }

  .table-header {
    flex-grow: 0;
    border-bottom: solid 1px cy-get-color("grey", "light-1");

    &__filters {
      .add-account-btn {
        margin: -8px 0 -8px auto;
      }
    }

    &__searchbar {
      margin-top: -4px;
      margin-bottom: -4px;

      ::v-deep {
        .v-input__slot {
          margin-bottom: 0;
        }

        .v-text-field {
          margin-top: 0 !important;

          &__details {
            display: none;
          }
        }

        .search-field {
          padding-top: 0;
        }
      }
    }
  }

  .slide-fade-left {
    &-enter-active {
      transform-origin: left center;
      transition: all 0.6s ease-in;
      animation: slide-in 0.6s;
    }

    &-enter {
      transform: translateX(-20px) !important;
      opacity: 0;
    }

    &-leave,
    &-leave-active {
      transition: none;
    }
  }

  .slide-fade-right {
    &-enter-active {
      transform-origin: right center;
      transition: all 0.45s ease;
    }

    &-enter {
      transform: translateX(20px) !important;
      opacity: 0;
    }

    &-leave,
    &-leave-active {
      transition: none;
    }
  }
}
</style>
